import React from "react"
import styled from "styled-components"
import { Link as LinkWrapper } from "gatsby"
import { useLocale } from "../../../hooks/useLocale"

// components
import ArrowRight from "../../icons/ArrowRight"
import Link from "../../atoms/Link"

// Styles
import colors from "../../../styles/colors"
import { fontWeights } from "../../../styles/textStyles"
import { fonts } from "../../../styles/fonts"

// Types
import { CardSVG } from "../DeepDiveSvgs"
import { IDeepdiveCard } from "../../../prismic-types/slices/deep-dive-cards"

const DeepdiveCard = ({
  label,
  description,
  link,
  foregroundColor,
  backgroundColor,
}: IDeepdiveCard) => {
  const { getLocalizedLink } = useLocale()

  return (
    <CardWrapper backgroundColor={backgroundColor}>
      <StyledLinkWrapper to={getLocalizedLink(link.href)}>
        <SVGWrapper>
          <CardSVG color={foregroundColor} />
        </SVGWrapper>
        <Card>
          <CardTite>
            <span>{label}</span>
          </CardTite>
          <CardDescription>{description}</CardDescription>
          <LinkContainer>
            <StyledLink
              to={link.href}
              type="internal"
              title={link.label}
              color={link.color}
            />
            <ArrowRight width={24} height={24} color={link.color} />
          </LinkContainer>
        </Card>
      </StyledLinkWrapper>
    </CardWrapper>
  )
}

export default DeepdiveCard

const CardWrapper = styled.div<{
  backgroundColor: string
}>`
  position: relative;
  border-radius: 12px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.cultureStrategy.light};
  box-shadow: 0px 2.72px 4.35px 0px rgba(4, 54, 74, 0.0105),
    0px 7.51px 12.02px 0px rgba(4, 54, 74, 0.015),
    0px 18.09px 28.94px 0px rgba(4, 54, 74, 0.0195),
    0px 60px 96px 0px rgba(4, 54, 74, 0.03);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0px 2.72px 4.35px 0px rgba(4, 54, 74, 0.02),
      0px 7.51px 12.02px 0px rgba(4, 54, 74, 0.02),
      0px 18.09px 28.94px 0px rgba(4, 54, 74, 0.04),
      0px 60px 96px 0px rgba(4, 54, 74, 0.04);

    & a {
      color: ${colors.main["100"]};
    }

    & svg,
    path#hover_color {
      fill: ${colors.main["100"]};
    }
  }
`

const SVGWrapper = styled.span`
  position: absolute;
  top: 0;
  width: 100%;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 64px 24px 24px 24px;
  z-index: 99;
`

const CardTite = styled.h3`
  font-family: ${fonts.primary};
  font-size: 32px;
  line-height: 40px;
  font-weight: ${fontWeights.bold};
  color: ${colors.main["100"]};
  letter-spacing: -1px;
  margin-bottom: 0px;
  height: 80px;

  display: flex;
  align-items: flex-end;

  & > span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }
`

const CardDescription = styled.p`
  font-family: ${fonts.karla};
  font-size: 18px;
  line-height: 24px;
  font-weight: ${fontWeights.regular};
  letter-spacing: -1%;
  color: ${colors.main["100"]};
  margin-bottom: 0px;
  height: 50px;

  // Truncation styles
  display: -webkit-box; // Display as a webkit box to support truncation
  -webkit-box-orient: vertical; // Set the orientation to vertical
  overflow: hidden; // Hide overflowing content
  text-overflow: ellipsis; // Add ellipsis for overflow
  -webkit-line-clamp: 2; // Show only 2 lines and truncate the rest
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledLink = styled(Link)<{ color: string }>`
  font-size: 18px;
  line-height: 20px;
  font-weight: ${fontWeights.medium};
  color: ${(props) => props.color};

  &:after {
    display: none;
  }
`

const StyledLinkWrapper = styled(LinkWrapper)`
  text-decoration: none;
`
